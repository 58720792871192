//----------------------------------------------------//
// TYPOGRAPHY                                         //
//----------------------------------------------------//
body {
  background: #fff;
  color: $black;
  font-family: $main_font, $fix_font;
  font-size: $main_font_size;
  font-weight: 400;
  line-height: $main_line_height;
}

h1, h2, h3, h4, h5, h6 {
  color: #4c4c4c;
  font-family: $main_font, $fix_font;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 28px;
  font-weight: 700;
}

h2 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
}

h3 {
  font-size: 22px;
  font-weight: 700;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
  font-weight: 700;
}

h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
}

p {
  padding: 0;
  margin: 0;
}

hr {
  overflow: hidden;
  height: 1px;
  background: #e9e9e9;
  border: 0;
  margin: 32px 0;
}

q,
blockquote {
  text-align: center;
  display: block;
  font-style: italic;
}

strong,
b {
  font-weight: 700;
}

a {
  color: $red;
  transition: $main_animation;
  text-decoration: none;
  &.button {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

a:hover {
  text-decoration: none;
  color: #ca0009;
}

a:active {
}

a:visited {
}

[class*="icon-"] {
  display: inline-block;
  vertical-align: top;
}

.content {
  a {
    &:hover {
    }
  }
}

img[style*="float: left"],
img[style*="float:left"],
img[align="left"] {
  margin: 0 20px 5px 0;
}

img[style*="float: right"],
img[style*="float:right"],
img[align="right"] {
  margin: 0 0 5px 20px;
}

ul {
  li {
    padding-bottom: 2px;
  }
}

.content__main {
  ul {
    li {
      position: relative;
      padding-left: 10px;
      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 10px;
        width: 4px;
        height: 4px;
        background: pink;
        border-radius: 50%;
      }
    }
  }
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
  counter-reset: item;
  li {
    display: block;
    position: relative;
    &:before {
      content: counters(item, ".") ". ";
      counter-increment: item;
      display: inline;
      vertical-align: top;
      font-weight: 700;
    }
  }
}

.upper {
  text-transform: uppercase;
}

