//----------------------------------------------------//
// LAYOUT                                             //
//----------------------------------------------------//
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  height: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
}

.fixcontent{
  flex: 1 0 auto;
}

.container {
  margin: 0 auto;
}

.justify-content-center{
  justify-content: center;
}

//----------------------------------------------------//
// HEADER                                             //
//----------------------------------------------------//

.header{
  display: flex;
  flex-direction: column;
  &__top{
    @include media-breakpoint-down(lg){
      order: 3;
    }
  }
  &__main{
    display: flex;
    width: 100%;
    align-items: center;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
    background: #fff;
    z-index: 10;
    @include media-breakpoint-down(lg){
      order: 2;
    }
    @include media-breakpoint-down(xs){
      .container{
        width: 100%;
      }
    }
    &--active{
      .nav__link{
        padding: 22px 5px;
      }
    }
  }
  &__logo{
    max-width: 100%;
    display: block;
    width: 100px;
    &-wrapper{
      display: flex;
      width: 100%;
      align-items: center;
      @include media-breakpoint-down(lg){
        padding: 5px 0;
      }
      @include media-breakpoint-down(lg){
        padding: 7px 0;
      }
    }
    &-link{
      display: block;
    }
    &-slogan{
      color: $black;
      font-size: 10px;
      display: block;
      text-align: center;
      margin: auto;
      @include media-breakpoint-down(md){
        font-size: 9px;
      }
    }
  }
  &__nav{
    display: flex;
    width: 100%;
    align-items: center;
    @include media-breakpoint-up(xl){
      display: flex!important;
      /*important for prevent close on mobile, than resize*/
    }
    @include media-breakpoint-down(lg){
      position: absolute;
      top: 70px;
      left: 0;
      height: auto;
      display: none;
      z-index: 2;
    }
    @include media-breakpoint-down(sm){
      top: 49px;
    }
    &-toggle{
      width: 26px;
      height: 40px;
      line-height: 1;
      padding: 0;
      position: absolute;
      top: 6px;
      left: 15px;
      &:focus,
      &:active{
        background: transparent;
      }
      @include media-breakpoint-up(xl){
        display: none;
      }
      @include media-breakpoint-down(md){
        top: 17px;
      }
      @include media-breakpoint-down(sm){
        top: 9px;
      }
    }
  }
  &__additional{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }
  &__lang{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    @include media-breakpoint-down(lg){
      order: 3;
      width: 62px;
      padding: 17px 0;
      text-align: center;
      margin-left: 30px;
      &:before,
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background: #000;
      }
      &:after{
        left: auto;
        right: 0;
      }
    }
    @include media-breakpoint-down(md){
      padding: 18px 0;
      margin-left: 25px;
    }
    @include media-breakpoint-down(sm){
      padding: 13px 0;
    }
    @include media-breakpoint-down(xs){
      width: 50px;
      margin-left: 12px;
    }
    &:hover{
      & .header{
        &__lang{
          &-list{
            display: block;
          }
          &-selected{
            &:after{
              border-top-color: $red;
            }
          }
        }
      }
    }
    &-selected{
      padding: 2px 7px 2px 5px;
      position: relative;
      cursor: pointer;
      &:after{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -2px;
        border: 3px solid transparent;
        border-top-color: $black;
        transition: $main_animation;
        @include media-breakpoint-down(lg){
          position: static;
          display: inline-block;
          vertical-align: middle;
          margin-top: 1px;
          margin-left: 2px;
        }
      }
    }
    &-list{
      display: none;
      position: absolute;
      background: #fff;
      left: 0;
      top: 100%;
      z-index: 100;
      box-shadow: 0 6px 4px 0 rgba(47, 46, 46, 0.03);
      @include media-breakpoint-down(lg){
        width: 100%;
      }
      &-item{
        text-align: left;
      }
    }
    &-link{
      display: block;
      padding: 0 5px;
      color: $black;
      cursor: pointer;
      &:hover{
        color: $red;
      }
    }
  }
  &__social{
    display: inline-block;
    vertical-align: middle;
    padding-left: 15px;
    @include media-breakpoint-down(lg){
      order: 2;
    }
    @include media-breakpoint-down(sm){
      padding-left: 0;
    }
  }
  &__breadcrumb{
    padding: 5px 0;
    @include media-breakpoint-down(lg){
      order: 4;
    }
  }
}

//----------------------------------------------------//
// SIDEBAR                                            //
//----------------------------------------------------//



//----------------------------------------------------//
// FOOTER                                             //
//----------------------------------------------------//

.footer {
  background: $black;
  padding: 37px 0;
  @include media-breakpoint-down(md){
    padding-bottom: 30px;
  }
  &__logo{
    max-width: 100%;
    width: 100px;
    &-wrapper{
      display: flex;
      width: 100%;
      align-content: flex-start;
      max-width: 174px;
      align-items: flex-start;
      padding-top: 4px;
      @include media-breakpoint-down(md){
        display: none;
      }
    }
  }
  &__copyright{
    &-link{
      color: #aaa;
      font-size: 12px;
    }
  }
  &__bottomnav{
    @include media-breakpoint-down(sm){
      padding: 0 20px;
    }
  }
}