//----------------------------------------------------//
// Inputs & Form & Controls				       		  //
//----------------------------------------------------//
fieldset {
  display: block;
  text-align: center;
}

label {
  display: block;
  &:hover {
    cursor: pointer;
  }
}

// Inputs
//----------------------------------------------------//
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
textarea,
select,
.input {
  background-color: $form_bg;
  border: 1px solid #fff;
  color: #fff;
  font-size: $form_font_size;
  line-height: $main_line_height;
  padding: 13px 17px;
  width: 100%;
  display: block;
  transition: all .3s ease-in-out;
  -webkit-appearance: none;
  border-radius: 0;
  @include placeholderColor(#eee);
  &:focus {
    border-color: $form_bg;
    box-shadow: 0 0 3px $red;
    background-color: $form_bg;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus{
    border: 1px solid #fff;
    -webkit-text-fill-color: #fff!important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;

  }
  &.error {
  }
  &.disabled,
  &[disabled="disabled"],
  &[disabled] {
    background-color: #f3f3f3;
  }
}

textarea {
  height: 106px;
}

input[type="file"] {
  display: none;
  & + label {
    display: block;
    background: #fff;
    font-size: 14px;
    line-height: 24px;
    padding: 25px 15px;
    color: #969696;
    text-align: center;
    border: 1px dashed #c4c4c4;
    transition: .2s ease-in-out;
    &:hover {
      border-color: #696969;
      background: #e9e9e9;
    }
  }
}

//----------------------------------------------------//
// SELECT [INPUT]																			//
//----------------------------------------------------//
select {
  display: block;
  @include clearSelect;
  transition: none;
  background-color: $form_bg;
  &:focus{
    background-color: $form_bg;
  }
}

option{
  background-color: $black;
  color: #fff;
  &:hover{
    background-color: $red;
  }
}

.ui-front {
  z-index: 9999;
}

// Buttons
//----------------------------------------------------//
button,
.btn {
  text-align: center;
  vertical-align: top;
  font-weight: 400;
  font-size: $main_font_size;
  line-height: $main_line_height;
  padding: 9px 20px;
  text-transform: uppercase;
  background: transparent;
  color: $black;
  border: 1px solid $black;
  border-radius: 0;
  position: relative;
  transition: all .3s ease-in-out;
  width: 100%;
  display: block;
  &:hover{
    color: $red;
    border-color: $red;
  }
  &:active,
  &:focus{
    color: #fff;
    background: $red;
    border-color: $red;
  }
  &.disabled,
  &[disabled="disabled"],
  &[disabled] {
    background: #ccc;
    color: #555;
    cursor: not-allowed;
  }
}

.btn-white{
  text-transform: none;
  color: #fff;
  border-color: #fff;
  padding: 6px 20px;
  &:hover{
    background: $red;
    color: #fff;
  }
}

.btn-red{
  padding: 8px 35px;
  background: $red;
  color: #fff;
  border-color: $red;
  font-size: 14px;
  &:hover{
    background: #fff;
    color: $red;
  }
}

.btn-arrow{
  border: 2px solid $red;
  width: 26px;
  height: 26px;
  position: relative;
  display: inline-block;
  padding: 0;
  &:before{
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid transparent;
    border-left: 0;
    border-right: 8px solid $red;
    transition: $main_animation;
  }
  &:hover,
  &:active,
  &:focus{
    background: $red;
    &:before{
      border-right-color: #fff;
    }
  }
}

.btn-arrow-light{
  border: none;
  position: relative;
  padding: 0;
  width: 12px;
  height: 18px;
  &:before,
  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 12px;
    transform: rotate(45deg);
    width: 10px;
    height: 1px;
    background: rgba($red, .8);
    transition: $main_animation;
  }
  &:after{
    top: 5px;
    transform: rotate(-45deg);
  }
  &:hover,
  &:focus,
  &:active{
    background: transparent;
    &:before,
    &:after{
      background: $red;
    }
  }
}

.btn-arrow-slider{
  border: none;
  position: relative;
  padding: 0;
  width: 12px;
  height: 18px;
  &:before,
  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 12px;
    transform: rotate(45deg);
    width: 12px;
    height: 3px;
    background: $black;
    transition: $main_animation;
    @include media-breakpoint-down(sm){
      width: 10px;
      height: 2px;
    }
  }
  &:after{
    top: 5px;
    transform: rotate(-45deg);
  }
  &:hover,
  &:focus,
  &:active{
    background: transparent;
    &:before,
    &:after{
      background: $red;
    }
  }
}

// Checkbox
//----------------------------------------------------//
input[type="checkbox"] {
  position: absolute;
  left: -9999px;
  & + label {
    display: inline-block;
    vertical-align: top;
    padding: 0 0 0 30px;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    line-height: 24px;
    width: auto !important;
    color: #fff;
    &:before, &:after {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      background: $form_bg;
      left: 0;
      top: 50%;
      margin-top: -8px;
      border: 1px solid #fff;
    }
  }
  &:not(:checked) + label {
    &:before {
    }
  }
  &:checked + label {
    &:after {
      transform: rotate(45deg);
      width: 5px;
      height: 8px;
      border-top-color: transparent;
      border-left-color: transparent;
      left: 5px;
      top: 50%;
      margin-top: -6px;
      background: transparent;
    }
  }
  &:disabled:checked + label {
    &:before {
    }
  }
  &:disabled:not(:checked) + label {
    &:before {
    }
  }
}

// Radio
//----------------------------------------------------//
input[type="radio"] {
  position: absolute;
  left: -9999px;
  & + label {
    display: inline-block;
    vertical-align: top;
    padding: 0 0 0 30px;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    line-height: 24px;
    width: auto !important;
    color: #fff;
    &:before, &:after {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      background: $form_bg;
      left: 0;
      top: 50%;
      margin-top: -8px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }
  &:not(:checked) + label {
    &:before {
    }
  }
  &:checked + label {
    &:after {
      transform: rotate(45deg);
      width: 8px;
      height: 8px;
      background: #fff;
      left: 4px;
      top: 50%;
      margin-top: -4px;
    }
  }
  &:disabled:checked + label {
    &:before {
    }
  }
  &:disabled:not(:checked) + label {
    &:before {
    }
  }
}

//----------------------------------------------------//
// JQUI => DATEPICKER																	//
//----------------------------------------------------//
.ui-datepicker {
  width: 242px;
  border-radius: 4px;
  box-shadow: 0 0 2px #ccc;
  padding: 10px !important;
  display: none;
  .ui-widget-header {
    background: #fff;
    border: 0;
    border-radius: 0;
    padding: 5px 35px 13px;
    .ui-datepicker-prev, .ui-datepicker-next {
      position: absolute;
      font-family: 'mymobstr-pt';
      top: 14px;
      color: #9B9B9B;
      font-size: 24px;
      height: 24px;
      width: 24px;
      &:hover {
        background: #fff;
        border: 0;
        text-decoration: none;
      }
    }
    .ui-datepicker-prev {
      left: 10px;
      &:before {
        content: '\e6fe';
      }
    }
    .ui-datepicker-next {
      right: 10px;
      &:before {
        content: '\e6ff';
      }
    }
    .ui-datepicker-title {
      color: #333;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .ui-datepicker-calendar {
    margin: 0 -10px;
    padding: 0 10px;
  }
  thead {
    th {
      font-size: 12px;
      line-height: 16px;
      color: #9B9B9B;
      font-weight: 600;
      text-transform: uppercase;
      padding: 0 0 6px;
    }
  }
  tbody {
    tr {
      td {
        .ui-state-default {
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          padding: 5px 8px;
          color: #333;
          border: 0;
          background: #fff;
          display: block;
          text-align: center;
          &.ui-state-hover {
            border: 0;
            background: #eee;
            text-decoration: none;
          }
          &.ui-state-highlight {
            color: #fff;
          }
          &.ui-state-active {
            background: #eee;
            text-decoration: none;
          }
        }
      }
    }
  }
}
