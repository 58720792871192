@import 'sprite',
'normalize&reset',
'bootstrap/bootstrap-grid',
'bootstrap/utilities/sizing',
'bootstrap/utilities/align',
'bootstrap/utilities/spacing',
'variables',
'fonts',
'mixins&includes',
'../lib/css-hamburgers/_sass/hamburgers/hamburgers',
'typography',
'forms',
'layout';

//----------------------------------------------------//
// MAIN 						 				      //
//----------------------------------------------------//

/*SVG*/

.icon-facebook {
  width: 9px;
  height: 16px;
  fill: #fff;
  @include media-breakpoint-down(sm) {
    width: 9px;
    height: 16px;
  }
}

.icon-youtube {
  width: 20px;
  height: 21px;
  fill: #fff;
  @include media-breakpoint-down(sm) {
    width: 18px;
    height: 22px;
  }
}

.icon-phone {
  width: 14px;
  height: 22px;
  fill: #fff;
}

.icon-cross {
  fill: #acacac;
  width: 14px;
  height: 14px;
  transition: $main_animation;
}

.icon-person {
  width: 16px;
  height: 20px;
  fill: #fff;
}

.icon-callback {
  width: 96px;
  height: 96px;
  @include media-breakpoint-down(sm) {
    width: 60px;
    height: 60px;
  }
}

.icon-book {
  width: 20px;
  height: 14px;
  vertical-align: middle;
  fill: white;
}

.icon-download {
  width: 12px;
  height: 14px;
  vertical-align: middle;
  fill: white;
}

.icon-service-phone {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  svg {
    width: 22px;
    height: 22px;
    fill: #FF0000;
    vertical-align: middle;
  }
}

.icon-phone-service {
  width: 22px;
  height: 22px;
  text-align: center;
  svg {
    width: 22px;
    height: 22px;
    fill: #FF0000;
    vertical-align: middle;
  }
}

.icon-service-printer {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  svg {
    width: 22px;
    height: 22px;
    fill: #FF0000;
    vertical-align: middle;
  }
}

.icon-service-letter {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  svg {
    width: 22px;
    height: 22px;
    fill: #FF0000;
    vertical-align: middle;
  }
}

.icon-bell {
  width: 17px;
  height: 17px;
  fill: #c3c3c3;
}

.icon-map-marker {
  width: 16px;
  height: 16px;
  fill: #c3c3c3;
}

.icon-person {
  width: 13px;
  height: 17px;
  fill: #c3c3c3;
}

.icon-mobile-phone {
  width: 11px;
  height: 18px;
  fill: #c3c3c3;
}

/*end SVG*/

/*LISTS and TYPICAL ELEMENTS*/

.social {
  &__list {
    font-size: 0;
    &-item {
      display: inline-block;
      vertical-align: middle;
      padding-left: 5px;
      padding-bottom: 0;
      @include media-breakpoint-down(md) {
        padding-left: 4px;
      }
      @include media-breakpoint-down(sm) {
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
  &__link {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $black;
    &--facebook {
      background: #435c90;
      border-color: #435c90;
      &:hover {
        background: #fff;
        .social {
          &__icon {
            fill: #435c90;
          }
        }
      }
    }
    &--youtube {
      background: #f6002d;
      border-color: #f6002d;
      &:hover {
        background: #fff;
        .social {
          &__icon {
            fill: #f6002d;
          }
        }
      }
    }
  }
  &__icon {
    transition: $main_animation;
  }
}

.cities {
  &__list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      display: block;
      text-align: center;
    }
    &-item {
      width: 180px;
      @include media-breakpoint-down(sm) {
        display: inline-block;
        vertical-align: middle;
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 4px;
      }
    }
  }
  &__link {
    font-size: 14px;
    &--active {
      background: $red;
      color: #fff !important;
      border-color: $red;
    }
  }
}

.nav {
  width: 100%;
  @include media-breakpoint-down(lg) {
    background: $black;
  }
  &__list {
    display: flex;
    justify-content: space-around;
    width: 100%;
    text-align: center;
    @include media-breakpoint-down(lg) {
      display: block;
      text-align: left;
    }
    &-item {
      vertical-align: middle;
      padding-bottom: 0;
      cursor: pointer;
      @include media-breakpoint-down(lg) {
        display: block;
      }
      &:hover {
        .nav {
          &__sublist {
            visibility: visible;
            opacity: 1;
            @include media-breakpoint-down(lg) {
              display: none;
            }
          }
          &__link {
            &:after {
              border-color: $red;
              @include media-breakpoint-down(lg) {
                transform: rotate(-135deg);
                margin-top: -1px;
              }
            }
          }
        }
      }
      &--sublist {
        position: relative;
        .nav {
          &__link {
            position: relative;
            padding-right: 19px;
            cursor: default;
            &:hover {
              &:before {
                background: transparent;
              }
            }
            &:after {
              content: '';
              position: absolute;
              right: 9px;
              top: 50%;
              margin-top: -4px;
              width: 4px;
              height: 4px;
              transform: rotate(45deg);
              border: 1px solid $black;
              border-top: 0;
              border-left: 0;
              transition: $main_animation;
              @include media-breakpoint-down(lg) {
                border-color: #fff;
                right: 15px;
              }
            }
          }
        }
      }
      &--active {
        .nav {
          &__link {
            cursor: default;
            color: $red;
            &:before {
              background: $red;
            }
          }
        }
      }
    }
  }
  &__link {
    text-transform: uppercase;
    color: $black;
    display: inline-block;
    padding: 26px 5px;
    position: relative;
    font-size: 12px;
    transition: $main_animation;
    @include media-breakpoint-down(lg) {
      color: #fff;
      padding: 9px 15px;
      letter-spacing: 0.7px;
      display: block;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 5px;
      width: calc(100% - 10px);
      height: 2px;
      transition: $main_animation;
      background: transparent;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    &:hover {
      color: $red;
      &:before {
        background: $red;
      }
    }
  }
  &__sublist {
    @include media-breakpoint-only(lg){
      transition: $main_animation;
    }
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 5px;
    background: #fff;
    min-width: calc(100% - 24px);
    max-width: 300px;
    text-align: left;
    z-index: 2;
    padding: 8px 0;
    box-shadow: 1px 6px 4px 0 rgba(47, 46, 46, 0.3);
    @include media-breakpoint-down(lg) {
      position: static;
      visibility: visible;
      opacity: 1;
      transform: none;
      padding: 4px 26px;
      display: none;
      background: $black;
    }
    &:before {
      content: '';
      position: absolute;
      top: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background: red;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &-item {
      padding: 0;
      @include media-breakpoint-down(lg) {
        padding: 0 5px;
      }
    }
    &-link {
      color: $black;
      font-size: 12px;
      padding: 1px 8px;
      display: block;
      white-space: nowrap;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        color: #fff;
        white-space: normal;
      }
      &:hover {
        color: $red;
      }
    }
  }
}

.bottomnav {
  &__list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    &-item {
      flex-grow: 1;
      @include media-breakpoint-down(sm) {
        width: 100%;
        padding-bottom: 20px;
      }
    }
  }
  &__title {
    color: #fff;
    padding-right: 10px;
    position: relative;
    padding-bottom: 9px;
    margin-bottom: 6px;
    @include media-breakpoint-down(sm) {
      font-size: 13px;
      padding-bottom: 20px;
      color: #848787;
      cursor: pointer;
    }
    &:after {
      content: '';
      position: absolute;
      background: rgba(255, 255, 255, 0.2);
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
    }
    &-text {
      display: inline-block;
      vertical-align: middle;
      @include media-breakpoint-down(sm) {
        padding-right: 6px;
      }
    }
    &-control {
      display: inline-block;
      vertical-align: middle;
      transform: rotate(-90deg);
      @include media-breakpoint-up(md) {
        display: none;
      }
      &:before,
      &:after {
        background: #848787;
        width: 7px;
        height: 1px;
        left: 3px;
        top: 9px;
      }
      &:after {
        top: 5px;
      }
    }
    &--active {
      color: #fff;
      .bottomnav {
        &__title {
          &-control {
            transform: rotate(90deg);
            &:before,
            &:after {
              background: #fff;
            }
          }
        }
      }
    }
  }
  &__sublist {
    padding-right: 10px;
    &-item {

    }
    &-link {
      color: #fff;
      display: inline-block;
      position: relative;
      font-size: 11px;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        transition: $main_animation;
        background: rgba(255, 255, 255, 0);
      }
      &:hover {
        color: #fff;
        &:after {
          background: #fff;
        }
      }
    }
  }
  &__target {
    display: block;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    @include media-breakpoint-up(md) {
      /*prevent hide after resize from mobile clicking*/
      display: block !important;
    }
    &--active {
      display: block;

    }
  }
  &__text {
    color: #fff;
    padding-right: 10px;
    line-height: 1.7;
    font-size: 12px;
    a {
      position: relative;
      display: inline-block;
      color: #fff;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        transition: $main_animation;
        background: rgba(255, 255, 255, 0);
      }
      &:hover {
        color: #fff;
        &:after {
          background: #fff;
        }
      }
    }
  }
}

.breadcrumb {
  &__wrapper {
    padding: 6px 0px 5px;
  }
  &__list {
    &-item {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding-right: 13px;
      margin-right: 4px;
      &:after {
        content: '>';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -9px;
        font-size: 11px;
        color: #c1bebe;
      }
      &:last-child {
        &:after {
          display: none;
        }
        .breadcrumb {
          &__link {
            color: $black;
          }
        }
      }
    }
  }
  &__link {
    font-size: 11px;
    font-style: italic;
    color: #c1bebe;
    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
}

.date {
  min-width: 43px;
  background: $red;
  z-index: 1;
  position: relative;
  display: block;
  color: #fff;
  padding: 4px;
  &-mounth {
    display: block;
    font-size: 8px;
    font-weight: bold;
    line-height: 1;
    text-align: left;
  }
  &-day {
    display: block;
    font-size: 19px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
  }
  &-year {
    display: block;
    font-size: 8px;
    font-weight: bold;
    line-height: 1;
    text-align: right;
  }
}

.pagination {
  font-size: 0;
  display: inline-block;
  vertical-align: middle;
  li {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 0;
    &.active {
      span {
        border: 1px solid $black;
      }
    }
  }
  a,
  span {
    display: block;
    border: 1px solid transparent;
    color: $black;
    width: 26px;
    text-align: center;
    font-size: 16px;
  }
  &__first {
    position: relative;
    height: 24px;
    &:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-left: 1px solid $black;
      border-top: 1px solid $black;
      transform: rotate(-45deg);
      left: 10px;
      top: 8px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 10px;
      background: $black;
      left: 7px;
      top: 6px;
    }
  }
  &__last {
    position: relative;
    height: 24px;
    &:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-left: 1px solid $black;
      border-top: 1px solid $black;
      transform: rotate(135deg);
      right: 10px;
      top: 8px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 10px;
      background: $black;
      right: 7px;
      top: 6px;
    }
  }
  &__prev {
    position: relative;
    height: 24px;
    &:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-left: 1px solid $black;
      border-top: 1px solid $black;
      transform: rotate(-45deg);
      left: 10px;
      top: 8px;
    }
  }
  &__next {
    position: relative;
    height: 24px;
    &:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-left: 1px solid $black;
      border-top: 1px solid $black;
      transform: rotate(135deg);
      right: 10px;
      top: 8px;
    }
  }
}

.service {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  &:hover {
    .service {
      &__image {
        transform: $image_scale;
      }
      &__content {
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);
      }
    }
  }
  &__title {
    font-weight: bold;
    color: #ed1c24;
    font-size: 17px;
    letter-spacing: -0.5px;
    line-height: 1.2;
    margin-bottom: 2px;
    height: 60px;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
      height: 63px;
      margin-bottom: 0;
    }
  }
  &__image {
    width: 100%;
    height: 148px;
    transition: $main_animation;
    display: block;
    @include media-breakpoint-down(lg) {
      height: 197px;
    }
    @include media-breakpoint-down(sm) {
      height: auto;
    }
    &-wrapper {
      border-top: 5px solid $red;
      margin-bottom: 15px;
      overflow: hidden;
    }
  }
  &__tile {
    display: flex;
    margin: 1px;
    border: 1px solid $black;
    flex: 1 1 0;
    align-items: center;
    justify-content: center;
    color: $black;
    text-transform: uppercase;
    padding: 5px 3px;
    text-align: center;
    @include media-breakpoint-down(sm) {
      padding: 4px 0;
    }
    &:hover,
    &:focus,
    &:active {
      border-color: $red;
      background: $red;
      color: #fff;
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: 136px;
      align-items: stretch;
      align-content: stretch;
      padding: 0 15px;
      margin-bottom: 15px;
      @include media-breakpoint-down(sm) {
        height: 106px;
      }
    }
    &-row {
      display: flex;
      flex: 1 0 0;
    }
  }
  &__text {
    padding: 0 15px;
    line-height: 1.55;
    text-align: justify;
    font-size: 12px;
    @include media-breakpoint-down(xs) {
      line-height: 1.8;
      font-size: 11px;
    }
  }
  &__content {
    box-shadow: 0 3px 6px #f1f1f1;
    padding-bottom: 15px;
    flex: 1 0 0;
    transition: $main_animation;
  }
}

.portfolio {
  &__single {
    &-content {
      height: 100%;
    }
    float: left;
    width: 33.33333%;
    height: 267px;
    overflow: hidden;
    position: relative;
    padding: 15px;
    @include media-breakpoint-down(md) {
      width: 50%;
      padding: 7px;
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      padding: 7.5px 0;
      width: 100%;
      height: 432px;
    }
    @include media-breakpoint-down(xs) {
      height: calc(100vw - 140px);
      padding: 0;
    }
    &:hover {
      .portfolio {
        &__single {
          &-bg {
            transform: $image_scale;
          }
          &-title {
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);
            background: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
    &-overlay {
      @include media-breakpoint-down(md) {
        padding: 0 25px;
        position: relative;
      }
    }
    &-wrapper {
      overflow: hidden;
      margin: -15px;
      @include media-breakpoint-down(md) {
        margin: -7px;
      }
      @include media-breakpoint-down(md) {
        margin: 0;
      }
    }
    &-bg {
      transition: $main_animation;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0);
      &-wrapper {
        position: absolute;
        left: 15px;
        top: 15px;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        overflow: hidden;
        @include media-breakpoint-down(md) {
          width: calc(100% - 15px);
          height: calc(100% - 15px);
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          height: calc(100% - 15px);
        }
        @include media-breakpoint-down(xs) {
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
    &-title {
      width: 60%;
      padding: 0 15px;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      position: relative;
      text-align: center;
      float: right;
      height: 100%;
      font-size: 14px;
      min-height: 133px;
      line-height: 1.5;
      display: flex;
      align-items: center;
      text-align: center;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);
      transition: $main_animation;
      @include media-breakpoint-down(xs) {
        margin: auto;
        font-size: 12px;
        min-height: 100px;
      }
    }
    &-nav {
      position: absolute;
      top: 50%;
      margin-top: -7px;
      @include media-breakpoint-up(md) {
        display: none;
      }
      &--prev {
        left: 0;
      }
      &--next {
        right: 0;
        transform: rotate(180deg);
      }
    }
    &--top {
      width: 66.66666%;
      height: 533px;
      @include media-breakpoint-down(md) {
        width: 100%;
        height: 300px;
      }
      @include media-breakpoint-down(sm) {
        height: 432px;
      }
      @include media-breakpoint-down(xs) {
        height: calc(100vw - 140px);
      }
      .portfolio {
        &__single {
          &-title {
            font-size: 16px;
            @include media-breakpoint-down(md) {
              font-size: 14px;
              padding: 19px;
              width: 78%;
            }
            @include media-breakpoint-down(xs) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.preview {
  position: relative;
  border: 1px solid #f0f0f0;
  padding: 0 0 12px;
  &__link {
    display: block;
    margin-bottom: 12px;
  }
  &__image {
    display: block;
    margin: auto;
    width: 100%;
    height: 188px;
    @include media-breakpoint-down(md) {
      height: 214px;
    }
    @include media-breakpoint-down(sm) {
      height: 168px;
    }
    @include media-breakpoint-down(xs) {
      height: auto;
      margin-bottom: 15px;
    }
  }
  &__name {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
    background: $red;
    color: #fff;
    padding: 6px 17px;
    font-size: 12px;
    font-weight: bold;
    width: 212px;
    letter-spacing: -0.1px;
  }
  &__cat {
    &-list {
      padding: 0 18px;
      max-height: 72px;
      overflow-y: auto;
      height: 90px;
      @include media-breakpoint-down(sm) {
        padding: 12px;
      }
      &-item {
        flex: 0 0 100%;
        padding-bottom: 0;
      }
    }
    &-link {
      font-size: 12px;
      &:hover {
        color: $black;
      }
    }
  }
}

/*end LISTS and TYPICAL ELEMENTS*/

.top {
  background: $black;
  padding: 20px 0;
  @include media-breakpoint-down(md){
    height: 70px;
  }
  @include media-breakpoint-down(sm) {
    padding: 9px 0;
    height: 55px;
  }
  &__tel {
    float: left;
    position: relative;
    @include media-breakpoint-down(lg) {
      float: right;
    }

    &-list{
      border:1px solid #ffffff;
      position: absolute;
      z-index: 12;
      top: 0;
      left: 0;
      @include media-breakpoint-down(md){
        left: auto;
        right: 0;
        z-index: 1;

      }
      &.hovered{
        border:1px solid #ccc;
        .top{
          &__tel{
            &-item{
              display: block;
              background: #ffffff;
              color: #000000;
              &:after{
                display: none;
              }
              &:first-of-type:after{
                color: $red;
                display: inline-block;
              }
              &-icon{
                fill:#000000;
              }
              &:hover{
                color: $red;
                .top__tel-number{
                  color: $red;
                }
                .top__tel-item-icon{
                  fill:$red;
                }
              }
            }
            &-number{
              color: #000000;
            }
          }
        }
      }

    }
    &-item{
      color: #ffffff;
      display: none;
      padding: 6px 0;
      position: relative;
      min-width: 220px;
      cursor: pointer;
      background: transparent;

      &:after{
        content: '\f0d7';
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-family: FontAwesome;
        font-size: 12px;
        right: 6px;
      }

      &:first-of-type{
        display: block;
      }
      &-wrapper{
        display: flex;
        align-items: center;
      }
      &-icon {
        /*background: url('../img/tel.png') #fff 50% 50% no-repeat;*/
        width: 13px;
        height: 22px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        transition: $main_animation;

        &-inner {
          fill: $black;
        }
      }
    }
    &-city{
      flex: 1;
      text-align: center;
      transition: $main_animation;

    }
    &-number{
      margin-right: 18px;
      font-size: 12px;
      color: #ffffff;
      transition: $main_animation;
    }



    &-select {
      padding: 6px 20px;
    }
    &-selected {
      &-wrapper {

      }
      &-city {
        min-width: 53px;
        display: inline-block;
        vertical-align: middle;
        padding-right: 7px;
        @include media-breakpoint-down(lg) {
          font-size: 11px;
        }
      }
      &-icon {
        /*background: url('../img/tel.png') #fff 50% 50% no-repeat;*/
        width: 13px;
        height: 22px;
        display: inline-block;
        vertical-align: middle;
        transition: $main_animation;
        &-inner {

        }
      }
      &-number {
        padding-left: 7px;
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
        @include media-breakpoint-down(lg) {
          font-size: 11px;
        }
      }
    }
    &-item {
      &-wrapper {

      }
      &-city {
        min-width: 53px;
        width: calc(100% - 140px);
        display: inline-block;
        vertical-align: middle;
        padding-right: 7px;
        text-align: center;
        @include media-breakpoint-down(lg) {
          font-size: 11px;
        }
      }

      &-number {
        padding-left: 7px;
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
        width: 126px;
        @include media-breakpoint-down(lg) {
          font-size: 11px;
        }
      }
    }
  }
  &__callback {
    &-wrapper {
      float: right;
      width: 214px;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}

/* jQUERY UI selectmenu customize*/

.ui-selectmenu-button.ui-button {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  line-height: $main_line_height;
  font-size: $main_font_size;
  padding: 4px 18px 4px 16px;
  width: 100%;
  display: block;
  transition: all .3s ease-in-out;
  -webkit-appearance: none;
  border-radius: 0;
  @include placeholderColor(#eee);
  @include media-breakpoint-down(lg) {
    padding: 3px 20px 3px 18px;
  }
  &:hover {
    background: #fff;
    border-color: #fff;
    color: $red;
  }
  &:focus {
    border-color: $form_bg;
    box-shadow: 0 0 3px $red;
    background-color: $form_bg;
  }
  &:after {
    content: '';
    position: absolute;
    border: 4px solid transparent;
    border-top: 4px solid #fff;
    right: 7px;
    top: 50%;
    margin-top: -2px;
  }
}

.ui-selectmenu-button.ui-button:focus {
  background: transparent;
  color: #fff;
  border-color: #fff;
  &:after {
    border-top-color: $red;
  }
}

.ui-button .ui-icon {
  display: none;
}

.ui-menu .ui-menu-item-wrapper {
  padding: 4px 6px 4px 16px;
  @include media-breakpoint-down(lg) {
    padding: 3px 20px 3px 18px;
  }
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  background: #fff;
  border-color: #fff;
  color: $red;
  .top {
    &__tel {
      &-item {
        &-icon {
          &-inner {
            fill: $red;
          }
        }
      }
    }
  }
}

.ui-widget.ui-widget-content {
  border-top-color: #fff;
}

/* end jQUERY UI selectmenu customize*/

.contact {
  &__title {
    background: url("../img/contact_bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    margin-bottom: 54px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 250px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
    &-text {
      color: #fff;
      position: relative;
      z-index: 1;
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.5);
    }
  }
  &__cities {
    padding-bottom: 35px;
  }
  &__content {
    padding-bottom: 35px;
  }
  &__map {
    padding-top: 8px;
    @include media-breakpoint-down(md) {
      padding-top: 0;
      padding-bottom: 15px;
    }
    @include media-breakpoint-down(xs) {
      display: none;
    }
    &-image {
      display: block;
      margin: auto;
      width: 500px;
      max-width: 100%;
    }
  }
  &__info {
    @include media-breakpoint-down(md) {
      font-size: 0;
    }
    &-list {
      @include media-breakpoint-down(md) {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        font-size: 15px;
      }
      @include media-breakpoint-down(xs) {
        display: block;
        width: auto;
      }
      &-item {
        padding-bottom: 56px;
        @include media-breakpoint-down(md) {
          padding-bottom: 20px;
        }
      }
    }
    &-title {
      color: $red;
      padding-bottom: 10px;
    }
    &-content {
      a {
        color: $black;
        &:hover,
        &:focus,
        &:active {
          color: $red;
        }
      }
    }
  }
  &__frame {
    height: 500px;
    @include media-breakpoint-down(sm) {
      height: 640px;
    }
    &-wrapper {
      position: relative;
    }
  }
  &__form {
    position: absolute;
    right: 75px;
    max-width: calc(100% - 90px);
    width: 372px;
    top: 50%;
    transform: translateY(-50%);
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      position: static;
      margin: auto;
      transform: none;
    }
    &-wrapper {
      position: absolute;
      width: 50%;
      height: 100%;
      background-color: rgba(3, 3, 3, 0.82);
      left: 0;
      top: 0;
      z-index: 1;
      @include media-breakpoint-down(sm) {
        width: 100%;
        height: 420px;
        top: auto;
        bottom: 0;
        padding: 15px;
      }
    }
    &-title {
      color: $red;
      padding-bottom: 28px;
    }
    &-content {
      padding-bottom: 42px;
    }
    &-field {
      padding-bottom: 4px;
      &-error {
        color: #ea212d;
        font-size: 11px;
        font-weight: 400;
        line-height: 11px;
        padding: 8px 0 4px;
      }
    }
    &-submit {
      display: inline-block;
    }
  }
}

/*hamburger customize*/

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background: #fff;
  width: 26px;
}

.hamburger-box {
  width: 26px;
}

/*end hamburger customize*/

.single {
  &__content {
    padding-top: 20px;
    padding-bottom: 43px;
  }
  &__button {
    margin-bottom: 12px;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
    &-text {
      display: block;
      font-size: 11px;
      color: #c1bebe;
      line-height: 1.3;
      &__next {
        float: right;
        display: block;
        font-size: 11px;
        color: #c1bebe;
        line-height: 1.3;
        @include media-breakpoint-down(md) {
          display: inline-block;
          vertical-align: middle;
          order: 2;
          flex-grow: 1;
          padding: 0 15px;
        }
      }
      @include media-breakpoint-down(md) {
        display: inline-block;
        vertical-align: middle;
        order: 2;
        flex-grow: 1;
        padding: 0 15px;
      }
    }
  }
  &__link {
    display: block;
    /*for correct sticky remove animation*/
    transition: 0s ease 0s;
    @include media-breakpoint-down(md) {
      display: flex;
      align-content: space-around;
      margin-bottom: 10px;
      align-items: center;
    }
    @include media-breakpoint-down(md) {
      background: #fff;
      z-index: 5;
    }
    &--disabled {
      opacity: 0.7;
      filter: grayscale(100%);
    }
    &--prev {
      text-align: left;
    }
    &--next {
      text-align: right;
      .single {
        &__button {
          @include media-breakpoint-down(md) {
            order: 3;
          }
          &:before {
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }
      }
    }
  }
  &__article {
    &-thumb {
      width: 100%;
      display: block;

      &-wrapper {
        position: relative;
        margin-bottom: 25px;
      }
    }
    &-title {
      position: absolute;
      margin: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      text-align: center;
      padding: 25px 60px;
      background: rgba(0, 0, 0, 0.7);
      font-size: 24px;
      color: #fff;
      line-height: 1.2;
      @include media-breakpoint-down(lg) {
        font-size: 32px;
      }
      @include media-breakpoint-down(md) {
        font-size: 26px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 22px;
        transform: none;
        padding: 10px 0;
        display: none;
      }
      &-mobile{
        display: none;
        font-size: 22px;
        text-align: center;
        margin-top: 20px;
        @include media-breakpoint-only(xs){
          display: block;
        }
      }
    }
    &-content {
      text-align: justify;
      font-size: 16px;
      p {
        margin-bottom: 24px;
      }
    }
  }
  &__date {
    &-wrapper {
      position: absolute;
      right: 15px;
      bottom: 21px;
      @include media-breakpoint-only(xs){
        bottom:auto;
        top: 10px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px solid $red;
        left: 7px;
        top: 7px;
      }
    }
  }
}

.news {
  &__title {
    background: url("../img/news_bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    margin-bottom: 54px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 250px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
    &-text {
      color: #fff;
      position: relative;
      z-index: 1;
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.5);
    }
  }
  &__content {
    padding-bottom: 30px;
  }
  &__date {
    &-wrapper {
      position: absolute;
      left: 32px;
      top: 16px;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px solid $red;
        left: -7px;
        top: -7px;
      }
    }
  }
  &__image {
    display: block;
    margin: auto;
    width: 100%;
    height: 237px;
    transition: $main_animation;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 242px;
    }
    @include media-breakpoint-down(xs) {
      height: auto;
    }
  }
  &__single {
    position: relative;
    padding-bottom: 17px;
    box-shadow: 0 0 21px rgba(34, 34, 34, 0.14);
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: $red;
      width: 100%;
      height: 5px;
    }
    &:hover {
      box-shadow: 0 0 21px rgba(34, 34, 34, 0.35);
      .news {
        &__image {
          transform: $image_scale;
        }
      }
    }
    &-inner {
      padding-bottom: 22px;
      overflow: hidden;
      display: block;
    }
    &-wrapper {
      padding: 35px 0;
      @include media-breakpoint-down(md) {
        max-width: 570px;
        margin: auto;
      }
    }
  }
  &__subtitle {
    padding: 0 15px 7px;
    height: 47px;
    overflow: hidden;
    font-size: 17px;
    @include media-breakpoint-down(xs) {
      height: auto;
    }
    &-link {
      color: $black;
    }
  }
  &__text {
    padding: 0 15px;
    font-size: 14px;
    height: 38px;
    overflow: hidden;
    @include media-breakpoint-down(xs) {
      height: auto;
    }
  }
  &__readmore {
    position: relative;
    margin-right: 22px;
    letter-spacing: 0.6px;
    font-weight: bold;
    color: $black;
    border-bottom: 1px dashed transparent;
    &:hover {
      color: $black;
      border-bottom: 1px dashed $black;
    }
    &:after {
      content: '';
      position: absolute;
      right: -22px;
      top: 50%;
      margin-top: -5px;
      border: 5px solid transparent;
      border-right: 0;
      border-left: 12px solid $red;
    }
    &-wrapper {
      text-align: right;
      padding: 4px 15px;
    }
  }
  &__pagination {
    display: inline-block;
    &-wrapper {
      text-align: center;
      padding: 3px 0;
    }
  }
}

.vacancies {
  &__title {
    background: url("../img/vacancies_bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    margin-bottom: 54px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 250px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
    &-text {
      color: #fff;
      position: relative;
      z-index: 1;
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.5);
    }
  }
  &__content {
    padding-bottom: 54px;
  }
  &__single {
    position: relative;
    margin-bottom: 46px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: $black;
    }
    &:last-child {
      margin-bottom: 0;
      &:after {
        display: none;
      }
      .vacancies {
        &__single {
          &-text {
            padding-bottom: 0;
          }
        }
      }
    }
    &-top {
      overflow: hidden;
      padding-bottom: 9px;
    }
    &-place {
      text-transform: uppercase;
      float: left;
      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }
    &-arrow {
      width: 28px;
      height: 28px;
      transform: rotate(-90deg);
      transition: $main_animation;
      &-wrapper {
        float: right;
      }
      &--active {
        transform: rotate(90deg);
      }
    }
    &-title {
      padding-bottom: 5px;
      color: $red;
      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
    }
    &-text {
      line-height: 1.4;
      padding-bottom: 54px;
      ul {
        list-style: disc;
        padding-left: 18px;
        li {
          line-height: 1.4;
          padding-bottom: 3px;
        }
      }
    }
    &-short {
      padding-bottom: 25px;
    }
    &-description {
      display: none;
    }
  }
}

.index {
  &__slider {
    &-wrapper {
      margin-bottom: 34px;
      background: $black;
      height: 350px;
      overflow: hidden;
      @include media-breakpoint-down(lg) {
        margin-bottom: 60px;
      }
      @include media-breakpoint-down(sm) {
        height: 300px;
        margin-bottom: 34px;
      }
    }
    &-item {
      height: 350px;
      background: $black;
      position: relative;
      overflow: hidden;
      @include media-breakpoint-down(sm) {
        height: 300px;
      }
    }
    &-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-position: 50% 50%;
      background-size: cover;
    }
    &-content {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        position: absolute;
        width: 50%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        left: 0;
        top: 0;
        @include media-breakpoint-down(md) {
          width: 100%;
        }

      }
      &-inner {
        position: relative;
      }
      &-title {
        font-size: 35px;
        line-height: 1.25;
        color: #fff;
        padding-bottom: 14px;
        @include media-breakpoint-down(sm) {
          font-size: 27px;
          padding-bottom: 33px;
        }
      }
      &-link {
        display: inline-block;
        padding-right: 22px;
        position: relative;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 70px;
        @include media-breakpoint-only(xs){
          margin-bottom: 25px;
        }
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -5px;
          border: 5px solid transparent;
          border-right: 0;
          border-left: 12px solid $red;
        }
      }
    }
    &-arrow {
      &-overlay {
        font-size: 0;
        position: static!important;
      }
      &-wrapper {
        display: inline-block;
        vertical-align: middle;
        margin-right: 2px;
        &:last-child {
          margin-right: 0;
        }
      }
      &--prev {

      }
      &--next {
        transform: rotate(180deg);
      }
    }
  }
  &__service {
    margin-bottom: 50px;
    &-slider {
      display: flex;
      justify-content: space-around;
      .slick-slide {
        @include media-breakpoint-down(sm) {
          height: auto;
        }
      }
      &-wrapper {
        position: relative;
        @include media-breakpoint-down(md) {
          padding: 0 45px;
        }
        @include media-breakpoint-down(sm) {
          padding: 0 10px;
        }
      }
    }
    &-single {
      padding: 0 10px 5px;
      flex: 1 1 25%;
      display: flex;

    }
    &-nav {
      position: absolute;
      top: 50%;
      margin-top: -7px;
      @include media-breakpoint-up(md) {
        display: none;
      }
      &--prev {
        left: 0;
      }
      &--next {
        right: 0;
        transform: rotate(180deg);
      }
    }
  }
  &__title {
    font-size: 36px;
    color: $red;
    padding-bottom: 45px;
    @include media-breakpoint-down(md) {
      font-size: 28px;
      padding-bottom: 28px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 16px;
      padding-bottom: 20px;
    }
  }
  &__portfolio {
    padding-bottom: 58px;
    @include media-breakpoint-down(md) {
      padding-bottom: 40px;
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 30px;
    }
    @include media-breakpoint-down(xs) {
      padding-bottom: 20px;
    }
  }
  &__about {
    padding-bottom: 44px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 18px;
    }
    &-video {
      float: left;
      width: 540px;
      height: 304px;
      margin: 4px 32px 20px 0;
      position: relative;
      @include media-breakpoint-down(md) {
        width: 100%;
        height: 362px;
        margin: 0 0 30px;
      }
      @include media-breakpoint-down(sm) {
        height: 267px;
      }
      @include media-breakpoint-down(xs) {
        height: 190px;
        margin: 0 0 22px;
      }
      &-overlay {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
        position: absolute;
        left: 0;
        top: 0;
      }
      &-play {
        border: none;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 96px;
        height: 96px;
        margin-top: -48px;
        margin-left: -48px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        &:before {
          content: '';
          position: absolute;
          border: 16px solid transparent;
          border-right: 0;
          border-left: 30px solid #fff;
          left: 50%;
          top: 50%;
          margin-top: -16px;
          margin-left: -14px;
          transition: $main_animation;
        }
        &:hover,
        &:focus,
        &:active {
          background-color: rgba(255, 255, 255, 0.5);
          &:before {
            border-left-color: $black;
          }
        }
      }
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    &-item {
      padding: 0 0 14px 28px;
      position: relative;
      overflow: hidden;
      font-size: 14px;
      text-align: justify;
      line-height: 1.4;
      @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 1.8;
      }
      @include media-breakpoint-down(sm) {
        font-size: 11px;
        line-height: 1.5;
        padding: 0 0 16px 24px;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        width: 10px;
        height: 10px;
        background: $red;
        @include media-breakpoint-down(sm) {
          width: 6px;
          height: 6px;
        }
      }
    }
  }
  &-news {
    &__date {
      &-wrapper {
        position: absolute;
        left: 32px;
        top: 16px;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border: 2px solid $red;
          left: -7px;
          top: -7px;
        }
      }
    }
    &__image {
      display: block;
      margin: auto;
      width: 100%;
      height: 237px;
      transition: $main_animation;
      @include media-breakpoint-down(md) {
        height: 300px;
      }
      @include media-breakpoint-down(sm) {
        height: 242px;
      }
      @include media-breakpoint-down(xs) {
        height: auto;
      }
    }
    &__single {
      position: relative;
      padding-bottom: 17px;
      box-shadow: 0 0 21px rgba(34, 34, 34, 0.14);
      transition: $main_animation;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @include media-breakpoint-down(md) {
        max-width: 570px;
        margin: auto;
      }
      @include media-breakpoint-down(xs) {
        padding-bottom: 11px;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background: $red;
        width: 100%;
        height: 5px;
        @include media-breakpoint-down(sm) {
          height: 3px;
        }
        @include media-breakpoint-down(xs) {
          height: 2px;
        }
      }
      &:hover {
        box-shadow: 0 0 21px rgba(34, 34, 34, 0.35);
        .index-news {
          &__image {
            transform: $image_scale;
          }
        }
      }
      &-inner {
        margin-bottom: 22px;
        overflow: hidden;
        display: block;
      }
      &-wrapper {
        padding: 1px 14px 34px;
        @include media-breakpoint-down(md) {
          padding-bottom: 9px;
        }
        @include media-breakpoint-down(sm) {
          padding-top: 9px;
        }
      }
    }
    &__subtitle {
      padding: 0 15px 7px;
      height: 47px;
      overflow: hidden;
      font-size: 17px;
      flex-grow: 1;
      @include media-breakpoint-down(xs) {
        height: auto;
      }
      &-link {
        color: $black;
      }
    }
    &__text {
      padding: 0 15px;
      font-size: 14px;
      height: 38px;
      overflow: hidden;
      @include media-breakpoint-down(xs) {
        height: auto;
      }
    }
    &__readmore {
      position: relative;
      margin-right: 22px;
      letter-spacing: 0.6px;
      font-weight: bold;
      color: $black;
      border-bottom: 1px dashed transparent;
      @include media-breakpoint-down(xs) {
        letter-spacing: 0;
        font-size: 10px;
        margin-right: 13px;
      }
      &:hover {
        color: $black;
        border-bottom: 1px dashed $black;
      }
      &:after {
        content: '';
        position: absolute;
        right: -22px;
        top: 50%;
        margin-top: -5px;
        border: 5px solid transparent;
        border-right: 0;
        border-left: 12px solid $red;
        @include media-breakpoint-down(xs) {
          margin-top: -2px;
          border: 3px solid transparent;
          border-right: 0;
          border-left: 7px solid #ed1c24;
          right: -13px;
        }
      }
      &-wrapper {
        text-align: right;
        padding: 4px 15px;
        @include media-breakpoint-down(xs) {
          padding: 8px 15px;
        }
      }
    }
  }
  &__news {
    padding-bottom: 47px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 32px;
    }
    &-slider {
      height: 440px;
      overflow: hidden;
      @include media-breakpoint-down(lg) {
        height: 503px;
      }
      @include media-breakpoint-down(sm) {
        height: auto;
      }
      &-wrapper {
        position: relative;
        width: 100%;
        @include media-breakpoint-down(md) {
          padding: 0 20px;
        }
        @include media-breakpoint-down(sm) {
          padding: 0 26px;
        }
      }
    }
    &-nav {
      &-item {
        display: inline-block;
        vertical-align: middle;
        margin-right: 2px;
        &:last-child {
          margin-right: 0;
        }
      }
      &-wrapper {
        position: absolute;
        right: 15px;
        top: -83px;
        font-size: 0;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      &--next {
        transform: rotate(180deg);
      }
      &--mobile {
        position: absolute;
        top: 50%;
        margin-top: -17px;
        @include media-breakpoint-up(lg) {
          display: none !important;
        }
        &--prev {
          left: 0;
          @include media-breakpoint-down(sm) {
            left: 15px;
          }
        }
        &--next {
          right: 0;
          transform: rotate(180deg);
          @include media-breakpoint-down(sm) {
            right: 15px;
          }
        }
      }
    }
  }
}

.works {
  &__title {
    background: url("../img/works_bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    margin-bottom: 54px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 250px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
    &-text {
      color: #fff;
      position: relative;
      z-index: 1;
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.5);
    }
  }
  &__content {
    padding-bottom: 33px;
    &-inner {
      padding-bottom: 37px;
    }
  }
  &__breadcrumbs {
    text-align: center;
  }
}

.work {
  padding-bottom: 76px;
  &__button {
    margin-bottom: 12px;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
    &-text {
      display: block;
      font-size: 10px;
      color: #c1bebe;
      line-height: 1.3;
      @include media-breakpoint-down(md) {
        display: inline-block;
        vertical-align: middle;
        order: 2;
        flex-grow: 1;
        padding: 0 15px;
      }
    }
  }
  &__link {
    display: block;
    margin-top: 5px;
    /*for correct sticky remove animation*/
    transition: 0s ease 0s;
    @include media-breakpoint-down(md) {
      display: flex;
      align-content: space-around;
      margin-bottom: 10px;
      align-items: center;
    }
    @include media-breakpoint-down(md) {
      background: #fff;
      z-index: 5;
    }
    &--prev {
      text-align: left;
    }
    &--next {
      text-align: right;
      margin-left: -7px;
      .work {
        &__button {
          @include media-breakpoint-down(md) {
            order: 3;
          }
          &:before {
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }
      }
    }
  }
  &__content {
    padding-top: 15px;
  }
  &__article {
    &-title {
      font-size: 24px;
      line-height: 1.25;
      padding-bottom: 24px;
      color: #231f20;
      @include media-breakpoint-down(xs) {
        font-size: 20px;
        text-align: center;
      }
    }
    &-thumb {
      max-width: 100%;
      margin: auto;
      display: block;
      &-wrapper {
        position: relative;
        margin-bottom: 7px;
        display: block;
      }
    }
    &-resize {
      position: absolute;
      bottom: 8px;
      right: 20px;
      display: inline-block;
      width: auto;
      padding: 10px 11px;
      line-height: 1;
      color: $red;
      border-color: $red;
      &:hover{
        background: $red;
        color: #ffffff;
      }
      &:active,
      &:focus,
      &:visited{
        background: transparent;
        color: $red;
      }
    }
    &-slider {
      height: 120px;
      overflow: hidden;
      &-wrapper {
        padding: 0 40px;
        position: relative;
        margin-bottom: 27px;
      }
      &-link {
        margin: 0 3px;
      }
      &-image {
        max-height: 120px;
        display: block;
        max-width: 100%;
        margin: auto;
        &-overlay {
          display: table;
          height: 120px;
          width: 100%;
        }
        &-wrapper {
          display: table-cell;
          vertical-align: middle;
        }
      }
      &-over {
        position: relative;
        &:before {
          content: '';
          transition: $main_animation;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0);
          position: absolute;
          left: 0;
          top: 0;
        }
        &:hover {
          &:before {
            background: rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
    &-nav {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -9px;
      &--prev {

      }
      &--next {
        left: auto;
        right: 0;
        transform: rotate(180deg);
      }
    }
    &-content {
      font-size: 16px;
      text-align: justify;
      margin-bottom: 37px;
    }
    &-additional {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 70px;
      @include media-breakpoint-down(md) {
        display: block;
        padding-bottom: 30px;
      }
    }
    &-video {
      &-wrapper {
        width: 460px;
        height: 259px;
        position: relative;
        max-width: 100%;
        flex-shrink: 0;
        @include media-breakpoint-down(md) {
          margin: auto auto 20px;
        }
        @include media-breakpoint-down(xs) {
          height: 250px;
        }
      }
      &-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: 50% 50%;
        background-size: cover;
        left: 0;
        top: 0;
      }
      &-title {
        position: absolute;
        width: 100%;
        color: #fff;
        left: 0;
        top: 0;
        font-weight: bold;
        font-size: 20px;
        z-index: 1;
        padding: 18px 25px;
        @include media-breakpoint-down(xs) {
          color: $black;
          top: -66px;
          padding: 18px 0;
        }

      }
      &-play {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -34px;
        margin-left: -34px;
        display: inline-block;
        width: 68px;
        height: 68px;
        border: 2px solid #fff;
        border-radius: 50%;
        &:after {
          content: '';
          position: absolute;
          border: 9px solid transparent;
          border-right: 0;
          border-left: 17px solid #fff;
          left: 50%;
          top: 50%;
          margin-left: -7px;
          margin-top: -9px;
          transition: $main_animation;
        }
        &:hover,
        &:focus,
        &:active {
          background: transparent;
          border-color: $red;
          &:after {
            border-left-color: $red;
          }
        }
      }
    }
    &-share {
      &-wrapper {
        width: 370px;
        border: 1px solid #ebebeb;
        padding: 42px 10px;
        text-align: center;
        margin-left: 50px;
        @include media-breakpoint-down(lg) {
          width: 200px;
        }
        @include media-breakpoint-down(md) {
          width: 370px;
          max-width: 100%;
          margin: auto;
        }
      }
      &-title {
        text-transform: uppercase;
        font-size: 16px;
        padding-bottom: 16px;
        color: #231f20;
      }
    }
  }
  &__usage {
    &-wrapper {

    }
    &-title {
      text-align: center;
      font-weight: bold;
      font-size: 29px;
      padding-bottom: 50px;
      color: #231f20;
    }
  }
}

@keyframes ringing {
  40% {
    transform: rotate(15deg);
  }
  80% {
    transform: rotate(-15deg);
  }
}

.callback {
  position: fixed;
  bottom: 30px;
  right: 30px;
  @include media-breakpoint-down(xs) {
    bottom: 20px;
    right: 20px;
  }
  &__link {
    display: block;
    transition: $main_animation;
    animation: ringing 0.5s infinite linear;
    &:hover {
      transform: rotate(15deg);
      animation: none;
    }
  }
  &__image {
    display: block;
    @include media-breakpoint-down(xs) {
      width: 60px;
    }
  }
}

/*Fancybox customize*/

.fancybox {
  &-bg {
    background: #231f20;
  }
  &-is-open {
    .fancybox {
      &-bg {
        opacity: 0.8;
      }
    }
  }
  &__nav {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    &--prev {
      left: -64px;
    }
    &--next {
      right: -64px;
      transform: rotate(180deg);
    }
    &--close {
      top: 17px;
      right: -64px;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 2px;
        transform: rotate(45deg);
        border: 0;
        background: $red;
        left: 1px;
        top: 10px;
        transition: $main_animation;
      }
      &:after {
        transform: rotate(-45deg);
      }
      &:hover {
        background: $red;
        &:before,
        &:after {
          background: #fff;
        }
      }
    }
  }
}

/*end Fancybox customize*/

/*modals*/

.modal {
  &__callback {
    padding: 0;
    text-align: center;
    width: 423px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.5);
    @include media-breakpoint-down(xs) {
      width: 300px;
    }
    &-inner {
      padding: 40px;
      @include media-breakpoint-down(xs) {
        padding: 20px;
      }
    }
    &-title {
      color: $red;
      font-size: 28px;
      padding-bottom: 33px;
      @include media-breakpoint-down(xs) {
        font-size: 24px;
        padding-bottom: 15px;
      }
    }
    &-field {
      padding-left: 48px !important;
      &:focus {
        & + .modal {
          &__callback {
            &-icon {
              &-wrapper {
                .modal {
                  &__callback {
                    &-icon {
                      fill: $red;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &-wrapper {
        text-align: left;
        display: block;
        margin-bottom: 19px;
        position: relative;
        @include media-breakpoint-down(xs) {
          margin-bottom: 7px;
        }
      }
    }
    &-icon {
      transition: $main_animation;
      fill:$gray;
      &-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        width: 49px;
      }
    }
    &-text {
      display: block;
      color: #fff;
    }
    &-submit {
      &-wrapper {
        display: inline-block;
        padding-top: 27px;
        @include media-breakpoint-down(xs) {
          padding-top: 7px;
        }
      }
    }
    &--close {
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      right: 0;
      top: 0;
      border: 0;
      &:hover,
      &:focus,
      &:active {
        border: 0;
        background: transparent;
        .icon-cross {
          fill: $red;
        }
      }
    }
  }
}

.modal {
  &__service {
    width: 769px;
    max-width: 100%;
    padding: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.5);
    @include media-breakpoint-down(xs) {
      width: 300px;
    }
    &-inner {
      padding: 40px;
      @include media-breakpoint-down(xs) {
        padding: 20px;
      }
    }
    &.with-response {
      width: 510px;
    }
    &-response {
      color: #ed1c24;
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      margin: 25px auto;
    }
    &-title {
      color: $red;
      font-size: 28px;
      padding-bottom: 33px;
      @include media-breakpoint-down(xs) {
        font-size: 24px;
        padding-bottom: 15px;
      }
    }
    &-subtitle {
      color: #ed1c24;
      text-align: left;
      margin-bottom: 19px;
      font-size: 18px;
      font-weight: 500;
      @include media-breakpoint-only(xs){
        margin-bottom: 0;
      }
    }
    &-field {
      padding-left: 48px !important;
      &--no-icon {
        padding-left: 17px !important;
      }
      &--text {
        color: #c3c3c3;
        text-align: left;
        padding: 13px 17px 13px 0 !important;
        font-size: 14px;
        font-weight: 700;
      }
      &--radio {
        & + label {
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid #fff;
          color: #fff;
          font-size: 13px;
          line-height: 1.4;
          display: flex !important;
          align-items: center;
          justify-content: center;
          height: 44px;
          display: block;
          display: flex;
          font-size: 11px !important;
          font-weight: 700;
          padding: 0 !important;
          transition: all .3s ease-in-out;
          text-align: center;
          line-height: 14px !important;
          &:after, &:before {
            display: none;
          }
        }
        &:checked + label {
          border-color: red;
        }
      }
      &:focus {
        & + .modal {
          &__callback {
            &-icon {
              &-wrapper {
                .modal {
                  &__callback {
                    &-icon {
                      fill: $red;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &:focus + span svg {
        fill: red;
      }
      &-wrapper {
        text-align: left;
        display: block;
        margin-bottom: 19px;
        position: relative;
        @include media-breakpoint-down(xs) {
          margin-bottom: 7px;
        }
      }
    }
    &-icon {
      transition: $main_animation;
      &-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        width: 49px;
      }
    }
    &-submit {
      &-wrapper {
        display: inline-block;
        padding-top: 27px;
        @include media-breakpoint-down(xs) {
          padding-top: 7px;
        }
      }
    }
    &--close {
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      right: 0;
      top: 0;
      border: 0;
      &:hover,
      &:focus,
      &:active {
        border: 0;
        background: transparent;
        .icon-cross {
          fill: $red;
        }
      }
    }
  }
}

/*end modals*/

.video {
  &__title {
    background: url("../img/video_bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    margin-bottom: 34px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 250px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
    &-text {
      color: #fff;
      position: relative;
      z-index: 1;
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.5);
    }
  }
  &__content {
    padding-bottom: 37px;
  }
  &__single {
    flex: 0 0 50%;
    padding-top: 25px;
    padding-bottom: 55px;
    @include media-breakpoint-down(lg) {
      padding: 0 0 30px;
    }
    @include media-breakpoint-down(md) {
      flex: 1 0 100%;
      max-width: 525px;
      margin: auto;
    }
    &:nth-child(odd) {
      padding-right: 60px;
      @include media-breakpoint-down(lg) {
        padding-right: 15px;
      }
      @include media-breakpoint-down(md) {
        padding-right: 0;
      }
    }
    &:nth-child(even) {
      padding-left: 60px;
      @include media-breakpoint-down(lg) {
        padding-left: 15px;
      }
      @include media-breakpoint-down(md) {
        padding-left: 0;
      }
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      @include media-breakpoint-down(md) {
        justify-content: center;
      }
    }
  }
  &__image-bg{
    background: rgba(#000, .5);
    transition: $main_animation;
  }
  &__preview {
    position: relative;
    display: block;
    margin-bottom: 12px;
    &:hover {
      .video {
        &__preview {
          &-button {
            background: transparent;
            border-color: $red;
            &:after {
              border-left-color: $red;
            }
          }
          &-title{
            color: $red;
            &:before{
              background: $red;
            }
          }
        }
        &__image-bg{
          box-shadow: 0px 0px 30px 1px rgba(0,0,0,0.75);
        }

      }
    }
    &-title{
      color: #000000;
    }
    &-image {
      height: 285px;
      width: 100%;
      display: block;
      margin-bottom: 20px;
      position: relative;
      z-index: -1;

      @include media-breakpoint-down(md) {
        height: 333px;
      }
      @include media-breakpoint-down(xs) {
        height: auto;
        min-height: 100px;
      }

      &-wrapper {
        position: relative;
      }
    }
    &-button {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -34px;
      margin-left: -34px;
      display: inline-block;
      width: 68px;
      height: 68px;
      border: 2px solid #fff;
      border-radius: 50%;
      &:after {
        content: '';
        position: absolute;
        border: 9px solid transparent;
        border-right: 0;
        border-left: 17px solid #fff;
        left: 50%;
        top: 50%;
        margin-left: -7px;
        margin-top: -9px;
        transition: $main_animation;
      }
      &:hover,
      &:focus,
      &:active {
        background: transparent;
        border-color: $red;
        &:after {
          border-left-color: $red;
        }
      }
    }
    &-title {
      line-height: 1.1;
      height: 52px;
      overflow: hidden;
      padding-left: 50px;
      position: relative;
      display: flex;
      align-items: center;
      transition: $main_animation;
      @include media-breakpoint-down(lg) {
        height: 78px;
      }
      @include media-breakpoint-down(xs) {
        height: auto;
      }
      &:before {
        content: '';
        position: absolute;
        width: 40px;
        height: 2px;
        top: 50%;
        margin-top: -1px;
        left: 0;
        background: $black;
        transition: $main_animation;
      }
    }
    &-text {
      text-align: justify;
      height: 66px;
      overflow: hidden;
      @include media-breakpoint-down(lg) {
        height: 88px;
      }
      @include media-breakpoint-down(xs) {
        height: auto;
      }
    }
  }
  &__breadcrumbs {
    text-align: center;
  }
}

.brochure {
  &__title {
    background: url("../img/brochures_bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    margin-bottom: 34px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 250px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
    &-text {
      color: #fff;
      position: relative;
      z-index: 1;
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.5);
    }
  }
  &__content {
    padding-bottom: 37px;
  }
  &__single {
    &-wrapper {
      border: 1px solid #f4f4f4;
      height: 100%;
      //display: flex;
      //flex-direction: column;
      //justify-content: space-between;
      transition: $main_animation;
      margin: 5px;
      &:hover {
        box-shadow: 0 0 27px rgba(1, 2, 2, 0.15);
      }
    }
    &-title-wrapper {
      position: relative;
    }
    &-image-wrapper {
      text-align: center;
      img {
        max-width: 100%;
        width: auto;
      }
    }
    &-title {
      background-color: #ed1c24;
      color: #ffffff;
      font-family: Arial;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      padding-left: 7px;
      display: flex;
      align-items: center;
      height: 50px;
      width: 90%;
      border: 1px solid #f8bdc2;
      left: -1px;
      top: -1px;
      position: relative;
    }
    &-actions {
      text-align: center;
      margin-bottom: 25px;
    }
    &-action {
      height: 30px;
      width: 30px;
      background: red;
      border: 1px solid red;
      display: inline-block;
      text-align: center;
      line-height: 28px;
      border-radius: 50%;
      margin-right: 10px;
      &--book {
        &:hover {
          background-color: white;
          svg {
            fill: red;
          }
        }
        svg {
          width: 20px;
          height: 14px;
          vertical-align: middle;
          fill: white;
        }
      }
      &--download {
        &:hover {
          background-color: white;
          svg {
            fill: red;
          }
        }
        svg {
          width: 12px;
          height: 14px;
          vertical-align: middle;
          fill: white;
        }
      }
    }
  }
  &__slider{
    &-nav{
      height: 26px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      .slick-arrow{
        position: absolute;
        bottom: 0;
        display: inline-block;
        width: 100%;
      }
      .slick-next{
        border: none;
        padding: 13px 11px;
        right: -22px;
      }
      .slick-prev{
        border: none;
        padding: 13px 11px;
        left: -22px;
      }
      .slick-next,
      .slick-prev{
        &:active,
        &:focus{
          background: transparent;
        }
      }
      .slick-nav{
        position: absolute;
        bottom: 0;
      }

      .slick-dots{
        text-align: center;
        display: block;
        li{
          display: inline-block;
          padding-bottom: 0;

          button{
            padding: 1px 9px;
            font-size: 16px;

            border:none;
            &:active,
            &:focus{
              background: transparent;
              color: #000;
            }
          }
          &.slick-active{
            background: transparent;
            border: 1px solid #0a0a0a;
            button{
              padding: 1px 9px;
              font-size: 16px;
            }
          }
        }
      }
    }

    &-dots{
    }

  }
  &__wrap{
    position: relative;
  }

}

.spareparts {
  &__title {
    background: url("../img/spareparts_bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    margin-bottom: 34px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 250px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
    &-text {
      color: #fff;
      position: relative;
      z-index: 1;
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.5);
    }
  }
  &__frame-wrapper {
    background: url('../img/spareparts_form_bg.jpg');
    min-height: 400px;
  }
  &__form-wrapper {
    margin-top: 65px;
    textarea {
      height: 80px !important;
    }
  }
  &__form-submit {
    display: inline-block;
    float: right;
  }
  &__content {
    padding-bottom: 37px;
  }
  &__single {
    &-wrapper {
      border: 1px solid #f4f4f4;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: $main_animation;
      &:hover {
        box-shadow: 0 0 27px rgba(1, 2, 2, 0.15);
      }
    }
    &-title-wrapper {
      position: relative;
    }
    &-image-wrapper {
      text-align: center;
      img {
        max-width: 100%;
        width: auto;
      }
    }
    &-title {
      display: inline-block;
      background-color: #ed1c24;
      color: #ffffff;
      font-family: Arial;
      font-size: 12px;
      font-weight: 700;
      line-height: 26px;
      padding: 7px;
      width: 90%;
      border: 1px solid #f8bdc2;
      left: -1px;
      top: -1px;
      position: relative;
    }
    &-actions {
      text-align: center;
      margin-bottom: 25px;
    }
    &-action {
      height: 30px;
      width: 30px;
      background: red;
      border: 1px solid red;
      display: inline-block;
      text-align: center;
      line-height: 28px;
      border-radius: 50%;
      margin-right: 10px;
      &--book {
        &:hover {
          background-color: white;
          svg {
            fill: red;
          }
        }
        svg {
          width: 20px;
          height: 14px;
          vertical-align: middle;
          fill: white;
        }
      }
      &--download {
        &:hover {
          background-color: white;
          svg {
            fill: red;
          }
        }
        svg {
          width: 12px;
          height: 14px;
          vertical-align: middle;
          fill: white;
        }
      }
    }
  }
}

.sparepart {
  &__single-wrapper {
    border: 1px solid #f0f0f0;
    height: 100%;
  }
  &__single-title-wrapper {
    height: 55px;
    padding: 0 15px;
    color: #ed1c24;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    display: flex;
    align-items: center;
  }
  &__single-description-wrapper {
    padding: 0 15px 15px;
    color: #231f20;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }
  &__single-image-wrapper {
    img {
      max-width: 100%;
      vertical-align: middle;
    }
  }
}

.manufacturers {
  margin: 7px 0 38px;
  position: relative;
  height: 38px;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  &__item-wrapper {
    margin: 0 15px;

  }
  &__item {
    flex-grow: 1;
    padding-left: 2px;
    padding-right: 2px;
    &.current{
      background-color: $red;
      color: white;
    }
    &:hover {
      background-color: red;
      color: white;
    }
  }
  &__slider-nav-left {
    position: absolute;
    top: 11px;
    left: 0;
    svg {
      width: 6px;
      height: 13px;
      cursor: pointer;
      &:hover {
        fill: red;
      }
    }
  }
  &__slider-nav-right {
    position: absolute;
    top: 11px;
    right: 0;
    svg {
      width: 6px;
      height: 13px;
      cursor: pointer;
      &:hover {
        fill: red;
      }
    }
  }
}

.about {
  &__title {
    background: url("../img/about_bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 460px;
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    margin-bottom: 34px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      padding-bottom: 110px;
    }
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 250px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
    &-text {
      color: #fff;
      position: relative;
      z-index: 1;
      text-align: center;
      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.5);
    }
  }
  &__content {
    text-align: justify;
    &-top {
      padding-bottom: 40px;
    }
    &-left {
      overflow: hidden;
      padding-bottom: 40px;
    }
    &-right {
      padding-bottom: 40px;
    }
  }
  &__tile {
    background: #fff;
    position: relative;
    margin-top: -138px;
    overflow: hidden;
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
      margin-top: 0;
      box-shadow: 0 0 5px #eceded;
    }
    &:hover {
      .about {
        &__tile {
          &-border {
            &--right {
              top: 0;
              transition: $main_animation;
            }
            &--bottom {
              right: 0;
              transition: $main_animation;
              transition-delay: 0.3s;
            }
            &--left {
              bottom: 0;
              transition: $main_animation;
              transition-delay: 0.6s;
            }
          }
          &-image {
            transform: $image_scale;
          }
        }
      }
    }
    &-border {
      &--top {
        background: $red;
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0;
        top: 0;
        z-index: 1;
      }
      &--right {
        background: $red;
        position: absolute;
        width: 3px;
        height: 100%;
        right: 0;
        top: -100%;
        z-index: 1;
      }
      &--bottom {
        background: $red;
        position: absolute;
        width: 100%;
        height: 3px;
        right: -100%;
        bottom: 0;
        z-index: 1;
      }
      &--left {
        background: $red;
        position: absolute;
        width: 3px;
        height: 100%;
        left: 0;
        bottom: -100%;
        z-index: 1;
      }
    }
    &-content {
      padding: 16px 18px 6px;
    }
    &-title {
      color: $red;
      font-weight: bold;
      font-size: 20px;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
    &-description {
      height: 54px;
      overflow: hidden;
      display: block;
      @include media-breakpoint-down(md) {
        height: 72px;
      }
    }
    &-image {
      height: 190px;
      width: 100%;
      transition: $main_animation;
      display: block;
      @include media-breakpoint-down(md) {
        height: 138px;
      }
      @include media-breakpoint-down(sm) {
        height: 334px;
      }
      @include media-breakpoint-down(xs) {
        height: auto;
      }
      &-wrapper {
        overflow: hidden;
      }
    }
  }
  &__image {
    display: block;
    width: 100%;
    height: 174px;
    @include media-breakpoint-down(md) {
      height: 125px;
    }
    @include media-breakpoint-down(sm) {
      height: 167px;
    }
    @include media-breakpoint-down(xs) {
      height: auto;
    }
    &-overlay {
      margin: -14px;
      font-size: 0;
      @include media-breakpoint-down(xs) {
        margin: auto;
      }
    }
    &-wrapper {
      padding: 14px;
      width: 50%;
      display: inline-block;
      vertical-align: middle;
      @include media-breakpoint-down(xs) {
        width: 100%;
        padding: 0 0 15px;
      }
    }
    &-link {
      display: block;
    }
  }
}

.catalog {
  padding-bottom: 32px;
  &__filter {
    display: flex;
    background: #feffff;
    align-items: stretch;
    @include media-breakpoint-up(lg) {
      border: 1px solid #edeeee;
    }
    @include media-breakpoint-down(xs) {
      padding: 0 25px;
      position: relative;
    }
    &-wrapper {
      padding: 15px 0;
      @include media-breakpoint-down(md) {
        padding-bottom: 30px;
      }
      @include media-breakpoint-down(xs) {
        padding-bottom: 42px;
      }
    }
    &-category {
      flex: 1 1 50%;
      @include media-breakpoint-down(md) {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        padding: 0 15px;
      }
      @include media-breakpoint-down(xs) {
        padding: 0 25px;
      }
    }
    &-firm {
      flex: 1 1 50%;
      position: relative;
      background: $black 50% 50% no-repeat;
      background-size: cover;
      transition: $main_animation;
      left: 0;
      top: 0;
      @include media-breakpoint-down(md) {
        flex: 1 1 100%;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 0.7;
      }
    }
    &-nav {
      @include media-breakpoint-up(sm) {
        display: none;
      }
      position: absolute;
      top: 50%;
      &--prev {
        left: 0;
      }
      &--next {
        right: 0;
        transform: rotate(180deg);
      }
    }
  }
  &__category {
    &-list {
      @include media-breakpoint-down(md) {
        display: flex;
        width: 100%;
        min-height: 76px;
        align-items: stretch;
      }
      &-item {
        padding-bottom: 0;
        @include media-breakpoint-down(md) {
          flex: 1 0 33.33333%;
          &:last-child {
            .catalog {
              &__category {
                &-link {
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
        }
        @include media-breakpoint-down(xs) {
          display: none;
        }
        &--active {
          @include media-breakpoint-down(xs) {
            display: block;
          }
        }
      }
    }
    &-link {
      padding: 0 30px;
      display: flex;
      align-items: center;
      align-content: left;
      position: relative;
      @include media-breakpoint-down(md) {
        height: 100%;
        position: relative;
        &:before {
          @include media-breakpoint-down(md) {
            content: '';
          }
        }

      }
      @include media-breakpoint-down(sm) {
        padding: 0 14px;
      }
      &:before {
        content: '';
        transition: $main_animation;
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparent;
        left: 0;
        top: 0;
      }
      &:after {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        border-top: 36px solid transparent;
        border-bottom: 36px solid transparent;
        border-left: 32px solid transparent;

        z-index: 1;
        transition: $main_animation;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      &:hover {
        position: relative;
        &:before {
          border: 2px solid $red;
        }
        &:after {
          border-left-color: $red;
        }
      }
      &--active {
        position: relative;
        &:before {
          background: $red;
        }
        &:after {
          border-left-color: $red;
        }
        .catalog {
          &__category {
            &-title {
              color: #fff;
            }
          }
        }
      }
    }
    &-thumb {
      width: 95px;
      height: 62px;
      margin: 5px 30px 5px 0;
      position: relative;
      flex: 0 0 auto;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &-title {
      flex: 1 1 auto;
      padding: 10px 0;
      font-weight: 700;
      font-size: 14px;
      color: $black;
      position: relative;
      transition: $main_animation;
      @include media-breakpoint-down(md) {
        text-align: center;
        color: #fff;
      }
    }
  }
  &__firm {
    &-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      height: 100%;
      max-width: 300px;
      margin: auto;
      @include media-breakpoint-down(md) {
        min-height: 364px;
        padding-top: 76px;
        max-width: 400px;
      }
      @include media-breakpoint-down(xs) {
        padding: 90px 0 15px;
      }
      &-item {
        flex: 1 0 100%;
        padding: 9px;
        @include media-breakpoint-down(md) {
          padding: 18px 9px;
        }
        @include media-breakpoint-down(sm) {
          padding: 10px 9px;
        }
      }
      &--two-col {
        .catalog {
          &__firm {
            &-list {
              &-item {
                flex: 1 0 50%;
              }
            }
          }
        }
      }
    }
    &-link {
      width: 130px;
      margin: auto;
      padding: 6px 20px;
      text-transform: uppercase;
      @include media-breakpoint-down(md) {
        width: 154px;
      }
      &--active {
        background: $red;
        border-color: $red;
      }
    }
  }
  &__tab {
    &-title {
      color: $red;
      text-transform: uppercase;
      position: relative;
      padding: 10px 0;
      margin-bottom: 31px;
      cursor: pointer;
      @include media-breakpoint-down(xs) {
        font-size: 14px;
        padding: 8px 0;
        margin-bottom: 23px;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background: $red;
        width: 100%;
        height: 3px;
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        border: 4px solid transparent;
        border-bottom: 0;
        border-top: 7px solid $red;
        margin-top: -4px;
        transition: $main_animation;
      }
    }
    &-preview {
      margin: 13px 0;
    }
    &-wrapper {
      @include media-breakpoint-down(sm) {
        position: relative;
        padding: 0 25px;
      }
    }
    &-nav {
      position: absolute;
      top: 50%;
      margin-top: -5px;
      @include media-breakpoint-up(sm) {
        display: none;
      }
      &--prev {
        left: 0;
      }
      &--next {
        right: 0;
        transform: rotate(180deg);
      }
    }
    &--hidden {
      & + * {
        display: none;
      }
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}

.product {
  padding: 15px 0 35px;
  &__button {
    margin-bottom: 10px;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
    &-text {
      display: block;
      font-size: 10px;
      color: #c1bebe;
      line-height: 1.3;
      &__next {
        display: block;
        font-size: 10px;
        color: #c1bebe;
        line-height: 1.3;
        float: right;
        @include media-breakpoint-down(md) {
          display: inline-block;
          vertical-align: middle;
          order: 2;
          flex-grow: 1;
          padding: 0 15px;
        }
      }
      @include media-breakpoint-down(md) {
        display: inline-block;
        vertical-align: middle;
        order: 2;
        flex-grow: 1;
        padding: 0 15px;
      }
    }
  }
  &__link {
    display: block;
    /*for correct sticky remove animation*/
    transition: 0s ease 0s;
    @include media-breakpoint-down(md) {
      display: flex;
      align-content: space-around;
      margin-bottom: 10px;
      align-items: center;
    }
    @include media-breakpoint-down(md) {
      background: #fff;
      z-index: 5;
    }
    &--disabled {
      opacity: 0.3;
      &:hover {
        span.btn {
          background-color: transparent;
          &:before {
            border-right-color: $red;
          }
        }
      }
    }
    &--prev {
      text-align: left;
    }
    &--next {
      text-align: right;
      .product {
        &__button {
          @include media-breakpoint-down(md) {
            order: 3;
          }
          &:before {
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }
      }
    }
  }
  &__content {

  }
  &__images {
    display: flex;
    align-items: stretch;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

  }
  &__title {
    font-size: 18px;
    padding: 5px 0 12px;
  }
  &__thumb {
    width: 100%;
    display: block;
    padding-right: 3px;
    height: 400px;
    @include media-breakpoint-down(md) {
      height: auto;
      width: auto;
      max-width: 100%;
      margin: 0 auto;
    }
    &-wrapper {
      flex: 1 1 80%;
      position: relative;
      .fullScreen {
        width: 30px;
        height: 30px;
        background: transparent;
        border: 1px solid $red;
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $main_animation;

        i {
          color: red;
          font-size: 16px;
          transition: $main_animation;
        }
        &:hover {
          background: $red;
          i {
            color: #ffffff;
          }
        }
      }
    }
    &-slider {
      height: 400px;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        height: auto;;
      }
    }
  }
  &__slider {
    height: calc(100% - 10px);
    overflow: hidden;
    @include media-breakpoint-down(md) {
      height: 100%;
    }
    &-wrapper {
      flex: 0 0 159px;
      width: 200px;
      padding: 12px 0;
      position: relative;
      height: 400px;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        flex: 0 0 100%;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding: 0 20px;
      }
    }
    &-item {
      margin: 3px 0;
      cursor: pointer;
    }
    &-link {
      display: block;
      position: relative;
    }
    &-image {
      display: block;
      width: 100%;
      height: 115px;
      border: 2px solid transparent;
      transition: $main_animation;
      &:hover {
        border-color: $red;
      }
      @include media-breakpoint-down(md) {
        max-width: 100%;
        width: auto;
        margin: 0 auto;
      }

    }
    &-nav {
      position: absolute;
      left: 50%;
      margin-left: -9px;
      z-index: 1;
      &--prev {
        transform: rotate(90deg);
        top: -3px;
        @include media-breakpoint-down(md) {
          left: 10px;
          top: 50%;
          transform: rotate(0deg) translate(0, -50%);
        }
      }
      &--next {
        transform: rotate(-90deg);
        bottom: -3px;
        @include media-breakpoint-down(md) {
          left: auto;
          right: 1px;
          top: auto;
          bottom: 50%;
          transform: rotate(180deg) translate(0, -50%);
        }
      }
    }
    /*&-horizontal{
      @include media-breakpoint-up(md){
        display: none;
      }
    }
    &-vertical{
      @include media-breakpoint-down(sm){
        display: none;
      }
    }*/
  }
  &__top {
    padding-bottom: 40px;
  }
  &__nav {
    background: $red;
    margin-bottom: 40px;
    &-list {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: 50px;
      align-items: stretch;
      &-item {
        display: flex;
        align-items: stretch;
        padding-bottom: 0;
      }
    }
    &-link {
      color: #fff;
      font-size: 12px;
      display: flex;
      align-items: center;
      &--active {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 3px solid #fff;
          left: 50%;
          margin-left: -5px;
          bottom: 0;
        }
      }
      &:hover {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  &__tab {
    &-content {
      table {
        border-collapse: collapse;
      }
      tr:nth-child(odd) {
        background: #dbdcde;
      }
      table,
      th,
      td {
        border: 2px solid #fff;
      }
      thead tr:nth-child(n) {
        background: #fff;
      }
      td,
      th {
        padding: 1px 3px;
      }
    }
    &-list {
      &-item {
        display: none;
        &--active {
          display: block;
        }
      }
    }
  }
}

.servicepage {
  color: #231f20;
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  padding-bottom: 20px;
  &__title {
    background: url("../img/service_bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    margin-bottom: 34px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 250px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
    &-text {
      color: #fff;
      position: relative;
      z-index: 1;
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.5);
    }
  }
  p {
    padding: 0 0 18px;
    text-align: justify;
  }
  &__row {
    padding-bottom: 12px;
  }
  &__image {
    max-width: 100%;
    height: auto;
  }
  &__cta-button {
    margin-top: 12px;
    display: inline-block;
  }
  &__link {
    color: $black;
  }
}

.contact-list {
  &__item {
    margin-top: 4px;
  }
  &__icon {
    margin-right: 6px;
    display: inline-block;
  }
}

.notfound {
  &__title {
    background: url("../images/index-slider-1.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    position: relative;
    color: #fff;
    align-items: center;
    margin-bottom: 54px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 250px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
    &-text {
      color: #fff;
      position: relative;
      z-index: 1;
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(35, 31, 32, 0.5);
    }
  }
  &__text {
    padding-bottom: 20px;
  }
  &__back {
    &-wrapper {
      display: inline-block;
    }
  }
}

///fixes////

table.MsoNormalTable {
  margin-left: 0 !important;
}

.slick-arrow.slick-hidden {
  display: inline-block;
  pointer-events: none;
  opacity: .4;

}

.work__article-video-title-mobile {
  position: static;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.work__article-video-title-desktop {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}


.mCSB_inside>.mCSB_container{
  margin-right: 0;
}
.mCSB_scrollTools{
  right: -4px;
  .mCSB_dragger{
    height: 20px;
    &:hover,
    &:active{
      .mCSB_dragger_bar{
        background-color: lighten($red, 20%);
      }
    }
    .mCSB_dragger_bar{
      background-color: $red;
      width: 8px;
      border-radius: 7px ;
    }
  }
  .mCSB_draggerRail{
    background-color: rgba(#000000, .2);
  }
}
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
  background-color: $red ;
}

///fixes END////